import {
  Inserter,
  SessionRole,
  DragAndDropContext,
  PartMenu,
  PartAdmin,
  PartPodcaster,
  ViewButtonCheckLoginToken,
  TinymcePortal,
  RouterScrollpos,
  RouterRedirects,
  RouterPageNotFound,
  MediaGlobalAudio,
  SessionPodcaster,
  ErrorBoundry,
} from "nystem-components";

import "../../../index.css";
import "./css/index.css";
import "./index.css";
import "./css/typography.css";
import "./css/layout.css";
import "./css/colors.css";
import "./css/form.css";
import "./css/program.css";
import "./css/Featuretext.css";
import "./css/player.css";
import "./css/front.css";
import "./css/popup.css";
import "./css/Formthings.css";
import "./css/faq.css";
import "./css/crewcarousel.css";
import "./css/episodecard.css";
import "./css/promotional-carousel.css";
import "./css/Crewmember.css";
import "./css/Mypage.css";
import "./css/Signupsection.css";
import "./css/slide.css";
import "./css/toggle-3d.css";
import "./css/Help.css";
import "./css/Mediaplayerexpanded.css";
import "./css/Mediaplayer.css";
import "./css/contentobjects.css";
import "./css/header.css";
import "./css/Navtab.css";

const Index = () => (
  <ErrorBoundry
    fallback={
      <>
        <PartMenu />
        <Inserter match="*" source="/content/page/error" />
      </>
    }
  >
    <RouterRedirects />
    <RouterScrollpos />
    <TinymcePortal />
    <PartMenu />

    <Inserter match="/content/page/*" />
    <Inserter match="/loginToken/*" source="/content/popup/*" />
    <Inserter match="/loginToken/" source="/user/verifyLoginLinkToken/" />
    <Inserter
      match="/loginToken/error"
      source="/content/popup/loginTokenError"
    />

    <Inserter match="/rabattkod" source="/code/enter" />
    <SessionRole userrole="logged-out">
      <ViewButtonCheckLoginToken />
      <Inserter match="/superadmin" source="/user/login" />
      <Inserter match={["/", "/loginToken/*"]}>
        <Inserter match="*" source="/content/frontlogin/loggedout" />
      </Inserter>
      <Inserter match="/program/details/*" className="program-page" />
      <Inserter match="/host/details/*" className="crewmember-page" />
    </SessionRole>
    <SessionRole userrole="logged-in">
      <Inserter match="/transaction/print/*" />
      <Inserter match="/minasidor*" className="mypage">
        <Inserter match="*" source="/menu/mysettings" />
        <Inserter match="/minasidor" source="/subscription/mysettings" />
        <Inserter
          match="/minasidor/edit/*"
          source="/subscription/mysettingsedit/*"
        />
        <Inserter match="/minasidor/kvitton" source="/transaction/mysettings" />
        <Inserter
          match="/minasidor/konto*"
          source="/user/mysettings/{_userid}"
        />
      </Inserter>
      <Inserter match="/program/details/*" className="program-page" />
      <Inserter
        match="/program/details/*/app"
        source="/program/appinstructions/*"
        className="program-page"
      />
      <Inserter match="/host/details/*" className="crewmember-page" />
      <Inserter match="/" className="start-logged-in">
        <Inserter match="*" source="/content/carousel" />
        <Inserter
          match="*"
          source="/episode/loggedinfront"
          className="episode-section"
          exact="true"
        />
      </Inserter>
    </SessionRole>
    <DragAndDropContext>
      <PartAdmin />
      <SessionPodcaster>
        <PartPodcaster />
      </SessionPodcaster>
    </DragAndDropContext>
    <RouterPageNotFound limit={5}>
      <Inserter match="*" source="/content/page/404" />
    </RouterPageNotFound>
    <footer>
      <Inserter match="*" source="/episode/player" />
    </footer>
    <MediaGlobalAudio />
  </ErrorBoundry>
);

export default Index;
